import { polaris } from '@theme-ui/presets'

export default {
    ...polaris,
    styles: {
        ...polaris.styles,
    },
    colors: {
        ...polaris.colors,
        background: 'hsl(233, 61.5%, 15.3%)', //#0F153F',
        text: 'hsl(210, 50%, 96%)',
        highlight: 'hsl(233, 61.5%, 25.3%)',
        secondary: 'hsl(210, 50%, 55%)'

    },
    fonts: {
        body: 'Roboto, system-ui, sans-serif',
        heading: 'Comfortaa, system-ui, sans-serif',
        monospace: '"Roboto Mono", monospace'
    },
    fontWeights: {
        body: 400,
        heading: 600,
        bold: 600
    },
    sizes: {
        ...polaris.sizes,
        container: 960,
    }
}
